<template>
  <div :class="{'active': isContentEditable}" class="editable-cell">
    <p @click="enableEdit()" :id="`content-${contentId}`" class="editable-area">{{ data.ratio }}</p>

    <!-- Cell actions -->
    <div v-if="isContentEditable" class="cell-actions">
      <p class="text">Change ratio for</p>

      <!-- Type selector for which to change the ratio -->
      <TabSelector @update="getType" :tabs="types"/>

      <!-- Panel for editing ratio for specific employee -->
      <EditEmployeeRatio v-if="activeType.text === 'User'" @click="disableEdit" :employee-name="data.employee"/>

      <!-- Actions -->
      <div class="actions">
        <p @click="cancelEdit()" class="cancel">Cancel</p>
        <p class="save">Save changes</p>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from "@vue/composition-api";
import EditEmployeeRatio from "../../editRatio/EditEmployeeRatio";
import TabSelector from "@/components/partials/editRatio/TabSelector";

export default {
  name: "EditableRatioCell",
  components: {
    TabSelector,
    EditEmployeeRatio
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    contentId: {
      type: [Number, String],
      required: true
    }
  },
  emits: ['save'],
  setup(props, {emit}) {
    let editableContent = ref(null);
    const isContentEditable = ref(false);

    onMounted(() => {
      editableContent.value = document.getElementById(`content-${props.contentId}`); // Get the HTML element.
    });

    function toggleEditableContent(value) {
      isContentEditable.value = value;
      editableContent.value.contentEditable = isContentEditable.value.toString();
    }

    /** Ratio type selector **/
    const types = ref([
      {
        id: 0,
        text: 'Log',
        isActive: true,
      },
      {
        id: 1,
        text: 'User',
        isActive: false,
      },
    ]);

    /** Enable content edit **/
    function enableEdit() {
      // Enable editing on the content.
      toggleEditableContent(true);

      // Focus on the element so the user can immediately start typing.
      editableContent.value.focus();

      // Add an event listener to allow only numbers.
      editableContent.value.addEventListener('keypress', allowNumbersOnly);
    }

    function allowNumbersOnly(event) {
      const key = parseInt(event.key);

      // If the input is not a number, input should not be allowed.
      if (!key) {
        event.preventDefault();
      }
    }

    /** Disable content edit **/
    function disableEdit() {
      // Disable editing on the content.
      toggleEditableContent(false);
    }

    /** Save edit **/
    function saveContent() {
      // Get the added content.
      const content = editableContent.value.innerText;

      // Emit the change to the parent.
      emit('save', {content});

      // Disable editing on the content.
      toggleEditableContent(false);
    }

    /** Cancel edit **/
    function cancelEdit() {
      editableContent.value.innerText = props.data.ratio; // Revert the content to the orginal.
      disableEdit(); // Disable editing on the content.
    }

    /** Change ratio for type **/
    const activeType = ref({});

    function getType(type) {
      activeType.value = type;
    }

    return {
      isContentEditable,

      /** Ratio type selector **/
      types,

      /** Enable content edit **/
      enableEdit,

      /** Disable content edit **/
      disableEdit,

      /** Cancel edit **/
      cancelEdit,

      /** Save edit **/
      saveContent,

      /** Change ratio for type **/
      activeType,
      getType,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/css/base.mixins";

.editable-cell {
  align-items: center;
  caret-color: var(--red-main);
  display: flex;
  flex-wrap: wrap;
  position: relative;
  //padding: rem(14) rem(20);

  .editable-area {
    &:focus {
      outline: 0 solid transparent;
    }
  }

  &.active {
    border: rem(1) solid var(--black-light);;
  }
}

// Cell actions
.cell-actions {
  @include position-elevation(absolute, $top: calc(100% + #{rem(7)}), $right: 0, $elevation: 1);
  background-color: white;
  border: 1px solid var(--gray-light-04);
  border-radius: rem(5);
  box-shadow: rem(2) rem(4) rem(8) 0 rgba(70, 70, 70, 0.25);
  padding: rem(16);
  width: rem(360);

  .text {
    font-size: rem(12);
    line-height: rem(15);
    margin-bottom: rem(7);
  }
}

.actions {
  align-items: center;
  display: flex;
  font-size: rem(12);
  font-weight: bold;
  justify-content: flex-end;
  line-height: rem(15);

  .cancel {
    @include hover-active-pointer();
    color: var(--red-main);
    margin-right: rem(18);
  }

  .save {
    @include hover-active-pointer();
    background-color: var(--red-main);
    border-radius: rem(4);
    color: white;
    padding: rem(6);
  }
}
</style>
