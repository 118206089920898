<template>
  <div v-if="orderTypes" class="order-details-wrapper">

    <!-- Export button -->
    <DButton v-if="orderHasLogs"
             @click.native="toggleExportModal()"
             :icon="require('../../assets/icons/svg/ic_download_white.svg')"
             text="Export to PDF"
             type="button"
             class="button-main button-export"/>

    <!-- Export to PDF modal -->
    <ExportHourReport v-if="isExportModalDisplayed"
                      @export-successful="onSuccessfulExport"
                      @close="toggleExportModal"
                      :dates="{startDate, endDate}"
                      :filters="{employeeIds, issueIds, departments, searchTerm}"
                      :logs="selectedRows"/>

    <!-- Top part of the content - Order information and hour information-->
    <div v-if="orderDetails" class="content-top">
      <OrderInformation @edit="editOrder" class="left-column"/>
      <HourDetails class="hour-indications"/>
    </div>

    <!-- Divider line -->
    <div class="divider-line"/>

    <!-- If there are no logs in the order. -->
    <NoRecordsYet v-if="orderDetails && !orderDetailsLogs.length && !areSearchOrFiltersActive"
                  @create="startAssigning"
                  :button-icon="require('../../assets/icons/svg/ic_next_active_white.svg')"
                  title="Start assigning hours"
                  text="This order doesn’t contain any logs yet. Start by assinging logs from the Jira import."
                  button-text="Start assigning"/>

    <!-- Bottom part of the content -->
    <div v-else class="content-bottom">

      <!-- Hour logs count -->
      <div v-if="orderDetails" class="hour-logs">
        <SectionTitleWithIcon :icon="require('../../assets/icons/svg/ic_time_primary.svg')" hasBackground
                              title="Hour logs"/>
        <BulkActions @logs-assigned="getOrderDetails"
                     @logs-unassigned="getOrderDetails"
                     @refresh="getOrderDetails"
                     @preset-updated="refreshOrderAndDetails"
                     @ratios-updated="refreshOrderAndDetails"
                     @marked-processed="refreshOrderAndDetails"
                     :logs="selectedRows"
                     assign-text="REASSIGN"
                     has-processed-option
                     has-back-to-unassigned
                     :has-export-preset="!isOrderCompleted"
                     :has-edit-ratio="!isOrderCompleted"
                     :has-order-assignment="!isOrderCompleted"/>
        <!-- The user should not be to edit the export preset of the log(s) if the order is NOT complete. -->
      </div>

      <!-- Search and filter bar -->
      <div class="search-with-filters">
        <SearchInputField @search="search"
                          :include-suggestions="false"
                          :result-count="null"
                          placeholder="Search for an hour log"
                          id="hourLogs"
                          class="search"/>
        <DateRange @date-selected="onDateSelection"
                   :has-title="false"
                   :start-value="getDateXDaysAgo(28)"
                   :end-value="getToday()"
                   class="date-filters"/>
        <FiltersButton @toggle-filters="toggleFilters" :is-active="areFiltersDisplayed" class="filters-button"/>
      </div>
      <Filters v-show="areFiltersDisplayed"
               :has-date-picker="false"
               @employee-selected="onEmployeeSelection"
               @issue-selected="onIssueSelection"
               @department-selected="onDepartmentSelection"
               class="filters"/>

      <!-- Logs table -->
      <OrderLogsTable v-if="orderDetails"
                      @selected-rows="getSelectedRows"
                      @refresh-table-data="refreshOrderAndDetails"
                      ref="orderLogsTable"/>
    </div>

    <!-- Edit order modal -->
    <EditOrderModal v-if="orderDetails && orderTypes.length > 0 && activeModal === editModal"
                    @order-edited="getOrderDetails"/>

    <!-- Delete order modal -->
    <Modal v-if="activeModal === deleteModal"/>
  </div>
</template>

<script>
import HourStatistic from "@/components/partials/hours/HourStatistic";
import Chip from "@/components/elements/Chip";
import {computed, onUnmounted, ref, watch} from "@vue/composition-api";
import TitleWithInfo from "../../components/elements/TitleWithInfo";
import HourInfo from "../../components/partials/HourInfo";
import NoRecordsYet from "../../components/partials/NoRecordsYet";
import {ROUTE_NAMES_MANAGE_LOGS} from "../../router/modules/manageLogs";
import BulkActions from "../../components/partials/bulkActions/BulkActions";
import DButton from "../../components/elements/DButton";
import SectionTitleWithIcon from "@/components/elements/SectionTitleWithIcon";
import OrderLogsTable from "@/components/partials/order/details/OrderLogsTable";
import ExportHourReport from "@/components/partials/export/ExportHourReport";
import EditOrderModal from "@/components/partials/order/EditOrderModal";
import {MODAL_CONTENT} from "@/models/ModalContent";
import {GET_ORDER_BY_ID} from "@/store/order/actions";
import Modal from "@/components/partials/Modal";
import SearchInputField from "../../components/partials/search/SearchInputField";
import FiltersButton from "../../components/partials/filters/FiltersButton";
import Filters from "../../components/partials/filters/Filters";
import DateRange from "@/components/partials/filters/DateRange";
import {getDateXDaysAgo, getToday} from "@/utils/dateManipulation";
import HourDetails from "../../components/partials/order/details/HourDetails";
import {TYPE_OF_CHANGE} from "../../models/reasons/Reason";
import {arraysAreEqual} from "../../utils/globals";
import getProject from "../../composables/projects/getProject";
import {getModalHelpers} from "../../composables/modalHelper";
import getOrderHelpers from "../../composables/order/getOrderHelpers";
import getOrderDetailHelpers from "../../composables/order/getOrderDetailHelpers";
import OrderInformation from "@/components/partials/order/details/OrderInformation";
import {ERR_TYPE} from "@/utils/handlers/ApiErrorMsgHandler";
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";
import {TOAST_CONTENTS} from "@/utils/handlers/toastHandler/ToastContents";
import {ROUTE_NAMES_PROJECT_OVERVIEW} from "@/router/modules/projectOverview";
import {ROUTE_NAMES_ORDER} from "@/router/modules/order";

export default {
  name: "OrderDetails",
  components: {
    OrderInformation,
    HourDetails,
    DateRange,
    Filters,
    FiltersButton,
    SearchInputField,
    Modal,
    EditOrderModal,
    ExportHourReport,
    OrderLogsTable,
    SectionTitleWithIcon,
    DButton,
    BulkActions,
    NoRecordsYet,
    HourInfo,
    TitleWithInfo,
    Chip,
    HourStatistic
  },
  setup(props, {root}) {
    const router = root.$router;
    const store = root.$store;
    const route = root.$route;

    // Get URL params.
    const {projectId, orderId} = root.$route.params;

    /** API Error **/
    const apiError = computed(() => store.getters.getApiError);
    watch(apiError, (newValue) => {
      if (newValue === ERR_TYPE.ORDER_NOT_FOUND) {
        ToastHandler.addNew(TOAST_CONTENTS.ORDER_NOT_FOUND);
        router.push({name: ROUTE_NAMES_ORDER.ORDER_OVERVIEW, params: {projectId}});
      }
    });

    /** Modal **/
    const deleteModal = MODAL_CONTENT.DELETE_ORDER().name;
    const editModal = MODAL_CONTENT.EDIT_ORDER().name;
    const {activeModal, setModal, closeModal} = getModalHelpers();

    /** Project's details **/
    const {getProjectDetails} = getProject();
    getProjectDetails(projectId);

    /** Edit order **/
    function editOrder() {
      setModal(MODAL_CONTENT.EDIT_ORDER());
    }

    /** Export to PDF **/
    const isExportModalDisplayed = ref(false);

    function toggleExportModal() {
      // If there are no selected rows, the user should export all the rows.
      if (selectedRows.value.length === 0) {
        selectedRows.value = orderDetailsLogs.value;
      }

      isExportModalDisplayed.value = !isExportModalDisplayed.value;
    }

    function onSuccessfulExport() {
      // Close the export modal.
      toggleExportModal();

      // The data should also be updated.
      getOrderDetails();
    }

    /** Selected rows **/
    const selectedRows = ref([]);

    function getSelectedRows(rows) {
      selectedRows.value = rows;
    }

    /** Assigning hours **/
    function startAssigning() {
      router.push({name: ROUTE_NAMES_MANAGE_LOGS.UNASSIGNED_LOGS});
    }

    /** Logs table **/
    const orderLogsTable = ref(null);

    function resetOrderLogsTable() {
      orderLogsTable.value?.resetTable();
    }

    function refreshOrderAndDetails() {
      // The data should be updated.
      getOrderDetails();

      // Get the project details, so the hour indications are updated.
      getProjectDetails(projectId);
    }

    /** Order types **/
    const {orderTypes, getOrderTypes} = getOrderHelpers();

    /** Filters **/
    const areFiltersDisplayed = ref(false);
    const areSearchOrFiltersActive = computed(() => {
      return searchTerm.value !== "" || employeeIds.value.length > 0 || issueIds.value.length > 0 || (startDate.value && endDate.value) || departments.value.length > 0;
    });

    function toggleFilters() {
      areFiltersDisplayed.value = !areFiltersDisplayed.value;
    }

    /** Filters - Employee **/
    const employeeIds = ref([]);

    function onEmployeeSelection(employees) {
      employeeIds.value = employees.map(employee => employee.id);
    }

    /** Filters - Issues **/
    const issueIds = ref([]);

    function onIssueSelection(issues) {
      issueIds.value = issues.map(issue => issue.id);
    }

    /** Filters - Date **/
    const startDate = ref('');
    const endDate = ref('');

    function onDateSelection(data) {
      startDate.value = data.startDate;
      endDate.value = data.endDate;
    }

    /** Filters - Department **/
    const departments = ref([]);

    function onDepartmentSelection(data) {
      departments.value = data.map(department => department.name);
    }

    /** Search **/
    const searchTerm = ref('');

    function search(text) {
      searchTerm.value = text;
    }

    // Every time a filter or the search term is updated, a new search should take place.
    watch([startDate, endDate, searchTerm, employeeIds, issueIds, departments], ([newStart, newEnd, newSearch, newEmployees, newIssues, newDepartments], [oldStart, oldEnd, oldSearch, oldEmployees, oldIssues, oldDepartments]) => {
      // On initial load, the search should take place only once.
      // This means that the new and old values should be different in order to perform the API call.
      const sameDates = newStart === oldStart && newEnd === oldEnd;
      if (sameDates && newSearch === oldSearch && arraysAreEqual(newEmployees, oldEmployees) && arraysAreEqual(newIssues, oldIssues) && arraysAreEqual(newDepartments, oldDepartments)) {
        // console.log('No NEED')
      } else {
        getOrderDetails();
      }
    }, {deep: true});

    /** Order Details **/
    const {
      orderDetails,
      resetSelectedOrder,
      isOrderCompleted,
      orderDetailsLogs,
      orderHasLogs
    } = getOrderDetailHelpers();
    getOrderTypes();

    function getOrderDetails() {
      resetOrderLogsTable();
      const searchedText = searchTerm.value;
      const employees = employeeIds.value;
      const issues = issueIds.value;
      const dateData = {startDate: startDate.value, endDate: endDate.value};
      const departmentData = departments.value;

      return store.dispatch(GET_ORDER_BY_ID, {
        orderId,
        searchedText,
        employees,
        issues,
        dateData,
        departmentData
      });
    }

    // Reset the selected order upon page exit.
    onUnmounted(() => {
      resetSelectedOrder();
    });

    return {
      getToday,
      getDateXDaysAgo,

      /** Modal **/
      deleteModal,
      editModal,
      MODAL_CONTENT,
      activeModal,

      /** Edit order **/
      editOrder,

      /** Export to PDF **/
      isExportModalDisplayed,
      toggleExportModal,
      onSuccessfulExport,

      /** Selected rows **/
      selectedRows,
      getSelectedRows,

      /** Assigning hours **/
      startAssigning,

      /** Logs table **/
      orderLogsTable,
      refreshOrderAndDetails,

      /** Order types **/
      orderTypes,

      /** Filters **/
      areFiltersDisplayed,
      toggleFilters,
      areSearchOrFiltersActive,

      /** Filters - Employee **/
      onEmployeeSelection,
      employeeIds,

      /** Filters - Issues **/
      issueIds,
      onIssueSelection,

      /** Filters - Date **/
      startDate,
      endDate,
      onDateSelection,

      /** Filters - Department **/
      departments,
      onDepartmentSelection,

      /** Search **/
      search,
      searchTerm,

      /** Order Details **/
      orderDetails,
      isOrderCompleted,
      orderDetailsLogs,
      orderHasLogs,
      getOrderDetails,

      /** Reason **/
      TYPE_OF_CHANGE,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";
@import "../../assets/css/base.variables";

.order-details-wrapper {
  position: relative;
  text-align: left;
}

// Top
.content-top {
  align-items: flex-start;
  display: flex;

  .left-column {
    //display: flex;
    //flex-wrap: wrap;
    flex-basis: 40%;
  }
}

// Bottom
.content-bottom {

}

// Hour logs count
.hour-logs {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: rem(25);
}

// Export
.button-export {
  @include position-elevation(fixed, $top: rem(130), $right: rem(40), $elevation: 60); // page-content padding and margin,
  box-shadow: 2px 4px 8px 0 rgba(70, 70, 70, 0.25);
  padding: 0 rem(19);
  text-transform: uppercase;
}
</style>
