<template>
  <div class="order-information">

    <!-- Order title & edit -->
    <div class="title-wrapper">
      <h1 class="main-title">{{ orderDetails.title }}</h1>
      <div @click="$emit('edit')" class="edit-button-small">
        <img src="../../../../assets/icons/svg/ic_edit.svg" class="icon" alt="edit"/>
      </div>
    </div>

    <!-- Order URL -->
    <!-- TODO: Uncomment when API is ready -->
    <!--    <div class="order-link">-->
    <!--      <a class="url">https://gyazo.com/c8128f6e2efsdsffrfsdfsdfdsfsdfsfweerw234123i4123</a>-->
    <!--    </div>-->

    <!-- Order type (chips) -->
    <div v-if="chipOptions" class="chips-wrapper">
      <Chip v-for="option in chipOptions"
            :key="option.id"
            :text="option.text"
            :custom-class="option.customClass"
            :icon="option.icon"
            class="page-chip"/>
    </div>

    <!-- Order date -->
    <DateTag :text="orderDetails.startAndEndDate" class="since-date"/>

    <!-- Hourly price -->
    <!-- TODO: Uncomment when API is ready -->
    <!--    <div class="hourly-price">-->
    <!--      <img src="../../../../assets/icons/svg/ic_time_secondary.svg" class="icon" alt="time"/>-->
    <!--      <span class="price">110	&euro;/h</span>-->
    <!--    </div>-->

    <!-- Attachment -->
    <!-- TODO: Uncomment when API is ready -->
    <!--    <div class="attachments-wrapper">-->
    <!--      <img src="../../../../assets/icons/svg/ic_time_secondary.svg" class="icon" alt="time"/>-->
    <!--      <a class="attachment">estimation_Billy.xlsx</a>-->
    <!--    </div>-->

  </div>
</template>

<script>
import Chip from "@/components/elements/Chip";
import DateTag from "@/components/elements/DateTag";
import {computed} from "@vue/composition-api";
import ChipOption, {CHIP_STYLES} from "@/models/ChipOption";
import getOrderDetailHelpers from "@/composables/order/getOrderDetailHelpers";

export default {
  name: "OrderInformation",
  components: {
    DateTag,
    Chip
  },
  props: {},
  emits: ['edit'],
  setup() {

    /** Order **/
    const {orderDetails} = getOrderDetailHelpers();

    /** Chips **/
    const chipOptions = computed(() => {
      const chips = [new ChipOption(orderDetails.value.type.type, orderDetails.value.type.color)]; // Create the chip based on the order type.

      // If the order is non-billable, add the related chip.
      if (!orderDetails.value.isBillable) {
        chips.push(new ChipOption('Non billable', CHIP_STYLES.LIGHT_GRAY));
      }

      return chips;
    });

    return {
      /** Order **/
      orderDetails,

      /** Chips **/
      chipOptions
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/css/base.mixins";
@import "../../../../assets/css/order/base.order-information";

// Order URL
.order-link {
  background-color: white;
  border: rem(1) solid var(--gray-light-02);
  border-radius: rem(3);
  box-shadow: rem(2) rem(4) rem(8) 0 rgba(70, 70, 70, 0.1);
  max-width: rem(270);
  padding: rem(6);

  .url {
    color: var(--red-main);
    font-family: var(--open-sans);
    font-size: rem(14);
    line-height: rem(19);

    // Form the ellipsis in the text.
    max-width: rem(250);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

// Hourly price
.hourly-price {
  align-items: center;
  display: flex;

  .icon {
    height: rem(24);
    margin-right: rem(8);
    width: rem(24);
  }

  .price {
    font-size: rem(14);
    font-family: var(--open-sans);
    font-weight: var(--open-sans-weight-bold);
    line-height: rem(19);
  }
}

// Attachments
.attachments-wrapper {
  align-items: center;
  display: flex;
  margin-top: rem(12);

  .icon {
    height: rem(24);
    margin-right: rem(8);
    width: rem(24);
  }

  .attachment {
    color: var(--red-main);
    font-family: var(--open-sans);
  }
}
</style>