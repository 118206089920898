<template>
  <div class="switch-box">
    <div class="switch-text-container">
      <p class="switch-title">{{ switchTitle }}</p>
      <p class="switch-text">{{ switchText }}</p>
    </div>
    <div class="switch-toggle">
      <label class="switch">
        <input v-model="switchValue"
               type="checkbox"
               :class="switchClass"
               @change="applySwitchChoice">
        <span class="slider round"></span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "BasicSwitch",
  props: {
    onSwitchUpdate: {
      type: Function,
      required: true
    },
    switchName: {
      type: String,
      required: false,
    },
    switchTitle: {
      type: String,
      required: false,
      default: ''
    },
    switchText: {
      type: String,
      required: false,
      default: ''
    },
    isSwitchOn: {
      type: [Number, Boolean],
      required: false,
    },
    switchClass: {
      type: String,
      required: false
    }
  },
  data: () => ({
    switchValue: false,
  }),
  methods: {
    applySwitchChoice() {
      if (this.$props.switchName) {
        this.$props.onSwitchUpdate(this.switchName, this.switchValue);
      } else {
        this.$props.onSwitchUpdate(this.switchValue);
      }
    }
  },
  created() {
    if (this.$props.isSwitchOn) {
      this.switchValue = true;
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.variables";
@import "../../assets/css/base.mixins";

$switchBackground: var(--gray-main);
$switchBackgroundOn: var(--red-light-02);
$switchColorOff: #757575;
$switchColorOn: var(--red-main);

.switch-box {
  align-items: center;
  display: flex;
}

.switch-title {
  font-size: rem(16);
  margin: 0 rem(12) 0 0;
  letter-spacing: 0;
  line-height: rem(22);
}

.switch-text {
  font-size: rem(14);
  letter-spacing: 0;
  line-height: rem(20);
}

.switch-toggle {
  display: flex;
  justify-content: space-between;
}

/* The switch - the box around the slider */
.switch {
  border-radius: 16px;
  display: inline-block;
  height: rem(20);
  position: relative;
  width: rem(36);
  margin-right: rem(10);
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $switchBackground;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: rem(18);
  width: rem(18);
  left: 0;
  bottom: rem(1);
  -webkit-transition: .4s;
  transition: .4s;
  background-color: $switchColorOff;
}

input:checked + .slider {
  background-color: $switchBackgroundOn;
}

input:focus + .slider {
  /*box-shadow: 0 0 1px #2196F3;*/
}

input:checked + .slider:before {
  background-color: $switchColorOn;
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media screen and (min-width: $screen-lg) {
  .switch-toggle {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: $screen-xxl) {
  /* The switch - the box around the slider */
  .switch {
    border-radius: 12px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    display: inline-block;
    height: 1.5em;
    position: relative;
    width: 3em;
    margin-right: 10px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $switchColorOff;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 1.5em;
    width: 1.5em;
    left: 0;
    bottom: 0;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: $switchColorOn;
  }

  input:focus + .slider {
    /*box-shadow: 0 0 1px #2196F3;*/
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(1.625em);
    -ms-transform: translateX(1.625em);
    transform: translateX(1.625em);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

@media screen and (min-width: $screen-tv) {
  .switch-title {
    font-size: 0.75vw;
  }
}
</style>
