<template>
  <div class="export-report-modal">
    <div class="modal-content">
      <!-- Title -->
      <h1 class="modal-title">Export hour report</h1>

      <!-- Actions -->
      <div class="actions-wrapper">
        <div class="action-column">
          <p class="title">Customise export</p>

          <!-- Name of report -->
          <h3 class="basic-input-title">Hour report title</h3>
          <BasicInput @on-input="(input) => (title = input)"
                      type="text"
                      id="title"
                      name="reportName"
                      placeholder="Enter the report name"
                      :debounce="0"
                      class="action-input"/>

          <!-- Name of the PDF file -->
          <h3 class="basic-input-title action-title">PDF file name</h3>
          <BasicInput @on-input="(input) => (fileName = input)"
                      :value="fileName"
                      type="text"
                      id="fileName"
                      name="fileName"
                      placeholder="Enter the report name"
                      :debounce="0"
                      class="action-input"/>

          <!-- Type selector -->
          <h3 class="basic-input-title action-title">Show employee's</h3>
          <TabSelector @update="getTab" :tabs="outputStrategies" initial-value/>

          <!-- Deduct hours -->
          <h3 class="basic-input-title">Deduct hours</h3>
          <div class="deduct-hours action-input">
            <BasicInput @on-input="getDeductedHours"
                        :error="deductedHoursValid ? '' : deductionError"
                        prompt="use decimals"
                        type="text"
                        id="deductHours"
                        name="deductHours"
                        :debounce="0"
                        placeholder="e.g. 6.5"/>
          </div>
        </div>
        <div class="action-column">
          <p class="title">Export summary</p>

          <p class="summary-row">
            <span class="row-title">Hour subtotal</span>
            <span class="row-data">{{ hourSubtotal }}h</span>
          </p>
          <!-- Excluded is the total of worklogs that have an 'export preset' value of 'exclude' -->
          <p class="summary-row">
            <span class="row-title">Hours excluded</span>
            <span class="row-data">-{{ hoursExcluded }}h</span>
          </p>
          <p class="summary-row">
            <span class="row-title">Hours deducted</span>
            <span class="row-data">-{{ deductedHoursValid ? deductedHours : 0 }}h</span>
          </p>

          <div class="divider-line-1 summary-divider"/>

          <p class="summary-row">
            <span class="row-title-bold">Total billable hours</span>
            <span class="row-data-bold">{{ totalBillableHours }}h</span>
          </p>

          <!-- Buttons -->
          <div class="button-wrapper">
            <DButton @click.native="$emit('close')"
                     text="Cancel"
                     type="button"
                     class="button-border action-button"/>
            <DButton @click.native="beforeExport"
                     :disabled="!isExportButtonEnabled"
                     text="Export to PDF"
                     :icon="require('../../../assets/icons/svg/ic_download_white.svg')"
                     type="button"
                     class="button-main action-button"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasicInput from "@/components/elements/BasicInput";
import TabSelector from "@/components/partials/editRatio/TabSelector";
import {computed, ref} from "@vue/composition-api";
import BasicCheckbox from "@/components/elements/BasicCheckbox";
import DButton from "@/components/elements/DButton";
import LogsTable from "@/components/partials/order/details/OrderLogsTable";
import {EXPORT_PDF} from "../../../store/log/actions";
import {formatIntoDashedDMY} from "@/utils/dateManipulation";
import getProject from "@/composables/projects/getProject";
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";
import {TOAST_CONTENTS} from "@/utils/handlers/toastHandler/ToastContents";

const deductionError = "Use digits, e.g. 6.00";

export default {
  name: "ExportHourReport",
  components: {
    LogsTable,
    DButton,
    BasicCheckbox,
    TabSelector,
    BasicInput,
  },
  props: {
    logs: {
      type: Array,
      required: true,
    },
    dates: {
      type: Object,
      required: true
    },
    filters: {},
  },
  emits: ["close", "export-successful"],
  setup(props, {root, emit}) {
    const store = root.$store;

    // Get URL params.
    const orderId = root.$route.params.orderId;

    /** Project **/
    const {projectDetails} = getProject();

    /** Order **/
    const orderDetails = computed(() => store.getters.getSelectedOrder);

    /** Emits **/
    function exportSuccessful() {
      emit('export-successful');
    }

    /** Hours **/
    const hourSubtotal = computed(() => {
      let hours = 0;
      props.logs.map((log) => {
        hours += log.hours;
      });

      return hours;
    });

    const hoursExcluded = computed(() => {
      let hours = 0;
      props.logs.map((log) => {
        hours += log.excludedHours;
      });
      return hours;
    });

    const totalBillableHours = computed(() => {
      let hours = hourSubtotal.value - hoursExcluded.value;

      // The deducted hours should be deducted only if they are valid.
      if (deductedHoursValid.value) {
        hours -= deductedHours.value;
      }
      return hours;
    })

    /** Tab selector **/
    const outputStrategies = ref([
      {
        id: 1,
        text: "Discipline",
        value: "discipline",
        isActive: true,
      },
      {
        id: 0,
        text: "Name",
        value: "user",
        isActive: false,
      },
    ]);
    const outputStrategy = ref({});

    function getTab(type) {
      outputStrategy.value = type;
    }

    /** Report title **/
    const title = ref("");

    /** PDF name **/
    const setSuggestedFilename = () => {
      const todayDate = formatIntoDashedDMY(new Date());
      const projectName = projectDetails.value.name;
      const orderName = orderDetails.value.title;
      const {startDate, endDate} = props.dates; // Get the selected period (filters) via props.
      return `${todayDate} - DTT - ${projectName} - Hour overview ${orderName} - ${startDate}_${endDate}`;
    }
    const fileName = ref(setSuggestedFilename());

    /** Deduction **/
    const deductedHours = ref('');

    function getDeductedHours(hours) {
      deductedHours.value = hours;
    }

    const deductedHoursValid = computed(() => {
      // Test the hours against the regex (zero and positive float number).
      const zeroNonNegativeFloat = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
      return zeroNonNegativeFloat.test(deductedHours.value);
    });

    /** Export button **/
    const isExportButtonEnabled = computed(() => !!title.value && deductedHoursValid.value && !!fileName.value); // The button is disabled if the title, fileName are not filled in and if the deducted hours are invalid.

    /** Export **/
    function beforeExport() {
      const {employeeIds, issueIds, departments, searchTerm} = props.filters;
      const data = {
        outputStrategy: outputStrategy.value.value,
        title: title.value,
        deductedHours: deductedHours.value,
        fileName: fileName.value,
        startDate: props.dates.startDate,
        endDate: props.dates.endDate,
        ids: props.logs.map(log => log.id),
        employeeIds,
        issueIds,
        departments,
        search: searchTerm,
        orderId
      };

      exportPdf(data).then(response => {
        if (response) {
          ToastHandler.addNew(TOAST_CONTENTS.LOGS_EXPORTED(fileName.value));
          exportSuccessful();
        }
      })
    }

    function exportPdf(data) {
      return store.dispatch(EXPORT_PDF, data);
    }

    return {
      deductionError,

      /** Hours **/
      hourSubtotal,
      totalBillableHours,

      /** Tab selector **/
      outputStrategies,
      getTab,

      /** Report title **/
      title,

      /** PDF name **/
      fileName,

      /** Deduction **/
      getDeductedHours,
      deductedHours,
      hoursExcluded,
      deductedHoursValid,

      /** Export button **/
      isExportButtonEnabled,

      /** Export **/
      beforeExport,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/forms";

$modal-border: #ececec;

.export-report-modal {
  @include position-elevation(fixed, $top: 0, $right: 0, $bottom: 0, $left: 0, $elevation: 100);
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;

  .modal-content {
    background-color: white;
    border: rem(1) solid $modal-border;
    border-radius: rem(10);
    box-shadow: rem(2) rem(4) rem(8) 0 rgba(70, 70, 70, 0.1);
    margin: rem(69) rem(100);
    max-height: calc(100% - 138px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: rem(40);
    text-align: left;
    width: 100%;
  }
}

.modal-title {
  color: var(--black-dark);
  font-size: rem(34);
  font-weight: bold;
  letter-spacing: 0;
  line-height: rem(42);
  margin-bottom: rem(24);
}

.modal-subtext {
  font-family: "Open Sans";
  font-size: rem(14);
  line-height: rem(19);
  margin-bottom: rem(40);
}

// Table
.modal-table {
  max-height: rem(250);
  position: relative;

  .modal-table-element {
    max-height: rem(250);
    overflow-x: hidden;
  }
}

.table-gradient {
  @include position(absolute, $left: 0, $bottom: 0, $right: 0);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
  height: rem(28);
}

// Actions
.actions-wrapper {
  display: flex;

  .action-column {
    flex-basis: 50%;
    flex-shrink: 0;

    .title {
      font-size: rem(20);
      font-weight: bold;
      line-height: rem(24);
      margin-bottom: rem(24);
    }

    .action-input {
      &:not(:last-child) {
        margin-bottom: rem(32);
      }
    }

    .action-title {
      margin-top: rem(32);
    }

    &:first-child {
      border-right: rem(1) solid var(--gray-main);
      padding-right: rem(45);
    }

    &:last-child {
      padding-left: rem(45);
    }
  }
}

.summary-row {
  display: flex;

  &:not(:last-child) {
    margin-bottom: rem(16);
  }

  .row-title {
    flex-grow: 1;
    font-size: rem(12);
    line-height: rem(15);
  }

  .row-title-bold {
    @extend .row-title;
    font-weight: bold;
  }

  .row-data {
    font-size: rem(20);
    line-height: rem(24);
  }

  .row-data-bold {
    @extend .row-data;
    font-weight: bold;
  }
}

.summary-divider {
  background-color: var(--gray-main);
  margin-bottom: rem(24);
  margin-top: rem(24);
}

.deduct-hours {
  align-items: center;
  display: flex;
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: rem(48);

  .action-button {
    align-items: center;
    display: flex;
    min-width: rem(180);
  }
}
</style>
