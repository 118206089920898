<template>
  <div class="hour-info">

    <div v-show="!isInfoFixed" :class="{'info-fixed': isInfoFixed}" class="info-inner">
      <HourStatistic title="Hours available" :amount="orderDetails.availableMinusSpent"/>

      <!-- Dividers -->
      <div class="divider-line-vertical"></div>

      <HourStatistic title="Total filtered hours"
                     :amount="orderDetails.totalFilteredHours"
                     :percentage="orderDetails.filteredSpentByAvailableHours"
                     title-shape-class="dark-green"/>

      <!-- Dividers -->
      <div class="divider-line-vertical"></div>

      <HourStatistic title="Total spent hours"
                     :amount="orderDetails.totalSpentAdjusted"
                     :percentage="orderDetails.availableByTotalSpent"
                     title-shape-class="light-green"/>

      <!-- Progress bar -->
      <ProgressBar :progress-amount="orderDetails.availableByTotalSpent"
                   :progress-amount-secondary="orderDetails.filteredSpentByAvailableHours"/>
    </div>

    <div v-show="isInfoFixed" class="info-fixed">
      <ProgressBar :progress-amount="orderDetails.availableByTotalSpent"
                   :progress-amount-secondary="orderDetails.filteredSpentByAvailableHours"
                   class="small-bar"/>

      <div class="statistics-box">
        <HourStatistic title="Hours available" :amount="orderDetails.availableMinusSpent" class="statistic-small"/>
        <HourStatistic title="Total filtered hours"
                       :amount="orderDetails.totalFilteredHours"
                       :percentage="orderDetails.filteredSpentByAvailableHours"
                       title-shape-class="dark-green"
                       class="statistic-small"/>
        <HourStatistic title="Total spent hours"
                       :amount="orderDetails.totalSpentAdjusted"
                       :percentage="orderDetails.availableByTotalSpent"
                       title-shape-class="light-green"
                       class="statistic-small"/>
      </div>
    </div>
    <!-- Observer that checks if the details are hidden. -->
    <Observer @intersected="updateVisibility" ref="observerElement"/>
  </div>
</template>

<script>
import HourStatistic from "../../hours/HourStatistic";
import {computed, ref, watch} from "@vue/composition-api";
import ProgressBar from "../../../elements/ProgressBar";
import Observer from "../../../elements/Observer";

export default {
  name: "HourDetails",
  components: {
    Observer,
    ProgressBar,
    HourStatistic
  },
  setup(props, {root}) {
    const store = root.$store;

    const orderDetails = computed(() => store.getters.getSelectedOrder);

    /** Styling **/
    const isInfoFixed = ref(false);

    function updateVisibility(isVisible) {
      isInfoFixed.value = !isVisible;
    }

    /** Observer **/
    let isObserverInitialized = ref(false);
    const observerElement = ref();
    initializeObserver();

    function initializeObserver() {
      // As soon as the data has been displayed, initialize the observer.
      root.$nextTick(() => {
        isObserverInitialized.value = true;
        observerElement.value.initializeObserver();
      })
    }

    watch(orderDetails, (newValue) => {
      if (!isObserverInitialized.value) {
        initializeObserver();
      }
    })


    return {
      orderDetails,

      /** Observer **/
      observerElement,
      updateVisibility,
      isInfoFixed
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/css/base.mixins";
@import "../../../../assets/css/base.variables";

// Relative
.hour-info {

  .info-inner {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .math-element {
      color: var(--gray-light-03);
      font-size: rem(24);
      line-height: rem(29);
    }
  }
}

// Fixed
.info-fixed {
  @include position-elevation(fixed, $top: var(--top-bar-height), $right: 0, $left: 0, $elevation: 101);
  align-items: center;
  background-color: white;
  border-top: rem(1) solid rgba(117, 117, 117, 0.2);
  box-shadow: 2px 4px 8px 0 rgba(70,70,70,0.25);
  display: flex;
  justify-content: space-between;
  padding: rem(6) rem(40);

  .statistics-box {
    display: flex;
    grid-gap: rem(40);
  }
}

</style>
