<template>
  <div class="edit-ratio-wrapper">
    <p class="main-text">{{ employeeName }}’s ratio for this order</p>

    <!-- Date range -->
    <div :class="{'inactive-option': allLogsInOrder}" class="dates-wrapper">
      <div class="date-input">
        <p class="search-issue-title">Starting date</p>
        <BasicDatePicker :isDisabled="allLogsInOrder"
                         data-cy="from-date-input"
                         id="fromDate"
                         name="fromDate"
                         placeholder-attr="DD/MM/YYYY"
                         v-model="fromDate"
                         class="ratio-date-picker"/>
      </div>
      <div class="date-input">

        <p class="search-issue-title">End date (optional)</p>
        <BasicDatePicker :isDisabled="allLogsInOrder"
                         data-cy="to-date-input"
                         id="toDate"
                         name="toDate"
                         placeholder-attr="DD/MM/YYYY"
                         v-model="toDate"
                         class="ratio-date-picker"/>
      </div>
    </div>

    <BasicSwitch :on-switch-update="updateLogsToggle"
                 :is-switch-on="false"
                 switch-title="All logs within this order"
                 class="toggle"/>

    <!-- Issue(s) -->
    <div class="issues-option">
      <div :class="{'inactive-option': everyIssueInOrder}" class="search-wrapper">
        <p class="search-issue-title">Issue(s)</p>
        <SearchInputField @search="search"
                          @select-item="selectIssue"
                          :is-disabled="everyIssueInOrder"
                          placeholder="e.g BIL-2"
                          id="issueSearch"
                          class="issue-search"/>

        <!-- Selected deleted -->
        <ItemWithRemoveButton v-for="issue in selectedIssues"
                              :key="issue.id"
                              :is-disabled="everyIssueInOrder"
                              @remove="removeIssue"
                              :project="issue"
                              class="issue-item"/>
      </div>

      <!-- Every issue within the order -->
      <BasicSwitch :on-switch-update="updateIssueToggle"
                   :is-switch-on="false"
                   switch-title="Every issue within this order"
                   class="toggle"/>
    </div>
  </div>
</template>

<script>
import BasicSwitch from "@/components/elements/BasicSwitch";
import SearchInputField from "@/components/partials/search/SearchInputField";
import ItemWithRemoveButton from "@/components/partials/ItemWithRemoveButton";
import {ref} from "@vue/composition-api";
import BasicDatePicker from "@/components/elements/BasicDatePicker";

export default {
  name: "EditEmployeeRatio",
  components: {
    ItemWithRemoveButton,
    SearchInputField,
    BasicSwitch,
    BasicDatePicker
  },
  props: {
    employeeName: {
      type: String,
      required: true
    }
  },
  setup(props) {
    /** Search deleted **/
    function search(item) {
      console.log(item)
    }

    /** Date range **/
    const fromDate = ref('');
    const toDate = ref('');

    /** Issues **/
    const selectedIssues = ref([]);

    function selectIssue(item) {
      // Add the clicked item to the selected jira projects.
      selectedIssues.value.push(item);
    }

    function removeIssue(item) {
      // Check if the item exists in the jira projects and remove it.
      const index = selectedIssues.value.findIndex(elem => elem.id === item.id);
      selectedIssues.value.splice(index, 1);
    }


    /** Every issue in order toggle **/
    const everyIssueInOrder = ref(false);

    function updateIssueToggle(value) {
      everyIssueInOrder.value = value;
    }

    /** All logs in order toggle **/
    const allLogsInOrder = ref(false);

    function updateLogsToggle(value) {
      allLogsInOrder.value = value;
    }

    return {
      /** Search deleted **/
      search,

      /** Date range **/
      fromDate,
      toDate,

      /** Issues **/
      selectedIssues,
      selectIssue,
      removeIssue,

      /** Every issue in order toggle **/
      everyIssueInOrder,
      updateIssueToggle,

      /** All logs in order toggle **/
      allLogsInOrder,
      updateLogsToggle,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";

.edit-ratio-wrapper {

  .main-text {
    font-size: rem(17);
    font-weight: bold;
    line-height: rem(20);
    margin-bottom: rem(32);
  }

  .toggle {
    margin-top: rem(15);
  }
}

// Dates
.dates-wrapper {
  display: flex;

  .date-input {
    width: 50%;

    :first-child {
      margin-right: rem(32);
    }
  }

  &::v-deep .ratio-date-picker input {
    width: 100%;
  }
}

// Issues
.issues-option {
  margin-bottom: rem(40);
  margin-top: rem(40);

  .issue-search {
    margin-bottom: rem(8);

    ::v-deep .search-field {
      height: rem(42);
    }
  }

  .issue-item {
    &:not(:last-child) {
      margin-bottom: rem(8);
    }

    &:last-child {
      margin-bottom: rem(16);
    }
  }
}

.search-issue-title {
  font-size: rem(12);
  line-height: rem(15);
  margin-bottom: rem(7);
}

// Toggles
.inactive-option {
  opacity: 0.3;
}
</style>
