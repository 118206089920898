import vuexStore from "../../store";
import {computed} from "@vue/composition-api";
import {RESET_SELECTED_ORDER} from "../../store/order/mutations";

export default function getOrderDetailHelpers() {
  const store = vuexStore;

  const orderDetails = computed(() => store.getters.getSelectedOrder);
  const isOrderCompleted = computed(() => orderDetails.value?.completed);
  const orderDetailsLogs = computed(() => store.getters.getSelectedOrder?.logs);
  const orderHasLogs = computed(() => store.getters.getSelectedOrder?.logs?.length);

  function resetSelectedOrder() {
    store.commit(RESET_SELECTED_ORDER);
  }

  return {
    orderDetails,
    isOrderCompleted,
    orderDetailsLogs,
    orderHasLogs,

    resetSelectedOrder,
  }
}