<template>
  <div class="edit-order-modal">

    <Modal @execute="editOrder" @close="resetForm" :disabled-right-button="!isFormValid">
      <template #content>
        <div class="edit-section">

          <!-- Order title -->
          <h3 class="basic-input-title input-title form-title">Order title</h3>
          <BasicInput @on-input="(input) => setFormData('title', input)"
                      :value="orderDetails.title"
                      :debounce="0"
                      type="text"
                      id="orderTitle"
                      name="orderTitle"
                      placeholder="e.g. OVSP"/>

          <!-- Order type -->
          <h3 class="basic-input-title input-title form-title">Order type</h3>
          <Dropdown @select-option="(input) => setFormData('orderType', input)"
                    :options="orderTypes"
                    :pre-selected-option="orderTypes.filter(type => orderDetails.type.id === type.id)[0]"
                    option-component-name="OrderTypeOption">
            <template #placeholder="{placeholder, selectedOption}">
              <OrderTypeOption :option="selectedOption" class="type-placeholder"/>
            </template>
          </Dropdown>

          <!-- Starting and end date -->
          <div class="dates-wrapper">
            <div class="date-input">
              <p class="basic-input-title">Starting date</p>
              <BasicDatePicker @value-logs="(input) => setFormData('startDate', input)"
                               :value-attr="orderDetails.startDate"
                               :to="formData.endDate.value"
                               data-cy="from-date-input"
                               id="startDate"
                               name="startDate"
                               placeholder-attr="DD-MM-YYYY"
                               class="ratio-date-picker"/>
            </div>
            <div class="date-input">

              <p class="basic-input-title">End date (optional)</p>
              <BasicDatePicker @value-logs="(input) => setFormData('endDate', input)"
                               :value-attr="orderDetails.end"
                               :from="formData.startDate.value"
                               data-cy="to-date-input"
                               id="toDate"
                               name="toDate"
                               placeholder-attr="DD-MM-YYYY"
                               class="ratio-date-picker"/>
            </div>
          </div>

          <!-- Available hours -->
          <h3 class="basic-input-title input-title form-title">Available hours</h3>
          <BasicInput @on-input="(hours) => formData.availableHours.value = hours"
                      :value="orderDetails.availableHours"
                      :error="availableHoursInvalid ? availableHoursError : ''"
                      :debounce="0"
                      prompt="use decimals"
                      type="text"
                      id="availableHours"
                      name="availableHours"
                      placeholder="e.g. 6.5"/>

          <!-- Already completed -->
          <BasicCheckbox id="editOrderCompleted"
                         :is-checkbox-checked="!!orderDetails.completed"
                         :callback="(value) => setFormData('completed', value)"
                         label-text="Order already completed"
                         class="already-completed checkbox-red"/>

          <!-- Billability -->
          <BasicCheckbox id="isBillable"
                         :callback="(input) => formData.isBillable.value = input"
                         :is-checkbox-checked="formData.isBillable.value"
                         label-text="Billable"
                         class="already-completed checkbox-red"/>
        </div>
      </template>

      <!-- Extra button for deletion -->
      <template #extraButton>

        <DButton @click.native="toggleDeleteOrderWarning()"
                 text="Delete"
                 type="button"
                 class="button-border modal-button button-delete"/>
      </template>
    </Modal>
  </div>
</template>

<script>
import {computed, onUnmounted, ref} from "@vue/composition-api";
import Modal from "@/components/partials/Modal";
import BasicInput from "@/components/elements/BasicInput";
import Dropdown from "@/components/elements/dropdown/Dropdown";
import OrderTypeOption from "@/components/partials/order/OrderTypeOption";
import BasicDatePicker from "@/components/elements/BasicDatePicker";
import BasicCheckbox from "@/components/elements/BasicCheckbox";
import {DELETE_ORDER, EDIT_ORDER} from "@/store/order/actions";
import DButton from "@/components/elements/DButton";
import {MODAL_CONTENT} from "@/models/ModalContent";
import {ROUTE_NAMES_MANAGE_LOGS} from "../../../router/modules/manageLogs";
import {getModalHelpers} from "../../../composables/modalHelper";
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";
import {TOAST_CONTENTS} from "@/utils/handlers/toastHandler/ToastContents";

const availableHoursError = "Use digits, e.g. 6.00";

export default {
  name: "EditOrderModal",
  components: {
    DButton,
    BasicCheckbox,
    OrderTypeOption,
    Dropdown,
    BasicInput,
    Modal,
    BasicDatePicker
  },
  props: {},
  emits: ['order-edited'],
  setup(_, {root, emit}) {
    const store = root.$store;
    const route = root.$route;
    const router = root.$router;
    const orderId = root.$route.params.orderId;
    const projectId = root.$route.params.projectId;

    // If the property is required and there is a value, the form is valid. Otherwise, it invalid.
    const isFormValid = computed(() => {
      const formIsFilled = Object.keys(formData.value).every(key => formData.value[key].isRequired ? !!formData.value[key].value : true);
      return formIsFilled && !availableHoursInvalid.value;
    });

    /** Order details **/
    const orderDetails = computed(() => store.getters.getSelectedOrder);

    /** Form **/
    let formData = ref({
      title: {
        value: null,
        isRequired: true
      },
      orderType: {
        value: null,
        isRequired: true
      },
      startDate: {
        value: null,
        isRequired: true
      },
      availableHours: {
        value: null,
        isRequired: false
      },
      endDate: {
        value: null,
        isRequired: false
      },
      completed: {
        value: null,
        isRequired: false
      },
      isBillable: {
        value: null,
        isRequired: false
      },
    });

    function setFormData(property, data) {
      formData.value[property].value = data;
    }

    function resetForm() {
      Object.keys(formData.value).map(key => {
        if (formData.value[key]) {
          setFormData(key, null);
        }
      })
    }

    // Set the data from the orderDetails in the form.
    Object.keys(orderDetails.value).map(key => {
      if (formData.value[key]) {
        setFormData(key, orderDetails.value[key]);
      }
    })

    /** Order type **/
    const orderTypes = computed(() => store.getters.getOrderTypes);

    /** Available hours **/
    const availableHoursInvalid = computed(() => {
      // Get the available hours from the formData (for the sake of less repetition).
      const availableHours = formData.value.availableHours.value;

      // Test the hours against the regex (zero and positive float number).
      const zeroNonNegativeFloat = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
      return !zeroNonNegativeFloat.test(availableHours);
    })

    /** Edit **/
    function editOrder() {
      let data = {};
      Object.keys(formData.value).forEach(key => data[key] = formData.value[key].value);
      data.orderType = formData.value.orderType.value.id
      data.orderId = orderId;

      editProjectOrder(data).then(response => {
        if (response) {
          // Display success toast.
          const movedToComplete = !orderDetails.value.completed && formData.value.completed.value; // If after the edit the order is completed, but it wasn't completed before the edit.
          ToastHandler.addNew(TOAST_CONTENTS.ORDER_EDITED(movedToComplete));

          // Set up the data to be sent through the event emit.
          emit('order-edited');

          // Reset the form.
          resetForm();

          // Close and reset the modal.
          closeModal();
        }
      });
    }

    function editProjectOrder(data) {
      return store.dispatch(EDIT_ORDER, data);
    }

    /** Delete order **/
    function deleteOrder() {
      return store.dispatch(DELETE_ORDER, orderId);
    }

    function onOrderDeletion() {
      deleteOrder().then(response => {
        if (response) {
          // Setup the data to be sent through the event emit.
          orderDeleted();
          closeModal();
        }
      })
    }

    function cancelOrderDelete() {
      // Close the delete modal.
      closeModal();

      // Open the edit modal.
      const modalContent = MODAL_CONTENT.EDIT_ORDER();
      setModal(modalContent);
    }

    function toggleDeleteOrderWarning() {
      const modalContent = MODAL_CONTENT.DELETE_ORDER(onOrderDeletion, cancelOrderDelete);
      setModal(modalContent);
    }

    function orderDeleted() {
      router.push({name: ROUTE_NAMES_MANAGE_LOGS.UNASSIGNED_LOGS});
    }

    /** Modal **/
    const {setModal, closeModal} = getModalHelpers();

    // Reset the form data upon page exit.
    onUnmounted(() => {
      resetForm();
    })

    return {
      /** Order details **/
      orderDetails,

      /** Form **/
      isFormValid,
      formData,
      setFormData,
      resetForm,

      /** Order type **/
      orderTypes,

      /** Available hours **/
      availableHoursInvalid,
      availableHoursError,

      /** Edit **/
      editOrder,

      /** Delete order **/
      toggleDeleteOrderWarning,
    }
  }
}
</script>


<style scoped lang="scss">
@import "../../../assets/css/base.variables";
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/layout.shared";
@import "../../../assets/css/forms";

.edit-order-modal {

  .form-title {
    margin-top: rem(32);
  }
}

// Order type
.type-placeholder {
  padding-left: 0;
}

// Starting and end date
.dates-wrapper {
  display: flex;
  margin-top: rem(32);

  .date-input {
    width: 50%;

    &:first-child {
      margin-right: rem(32);
    }
  }

  &::v-deep .ratio-date-picker input {
    width: 100%;
  }
}

// Buttons
.button-delete {
  margin-right: auto;
  width: rem(100);
}

// Already completed
.already-completed {
  margin-top: rem(20);
}
</style>
