export default class CheckboxController {
  //TODO: Simplify and document.
  constructor() {
    this.selectedRows = [];
    this.hasDetailsOpen = [];
  }

  selectAllRows(allData) {
    this.selectedRows.length < allData.length ? this.selectAll(allData) : this.deselectAll();
  }

  selectAll(allData) {
    allData.map(dataRow => {
      let exists = this.selectedRows.find(row => row.id === dataRow.id);
      if (!exists) {
        this.addRow(dataRow);
      }
    });
  }

  deselectAll() {
    this.selectedRows = [];
  }

  selectRow(rowData) {
    let exists = this.selectedRows.find(row => row.id === rowData.id);
    !exists ? this.addRow(rowData) : this.removeRow(rowData);
  }

  addRow(row) {
    this.selectedRows.push(row);
  }

  removeRow(row) {
    this.selectedRows = this.selectedRows.filter(r => r.id !== row.id);
  }

  resetSelectedRows() {
    this.selectedRows = [];
  }

  exists(rowData) {
    return this.selectedRows.find(row => row.id === rowData.id);
  }

  /**
   * Checks if a checkbox is checked, based on its id.
   * @param id {number}
   * @return {*}
   */
  isCheckboxChecked(id) {
    return !!this.selectedRows.find(row => id === row.id);
  }

  setDetailsOpen(rowData) {
    let exists = this.hasDetailsOpen.find(row => row.id === rowData.id);

    if (!exists) {
      this.hasDetailsOpen.push(rowData);
    } else {
      this.hasDetailsOpen = this.hasDetailsOpen.filter(r => r.id !== rowData.id);
    }
  }

  isCheckboxAllChecked(tableData) {
    return this.selectedRows.length === tableData.length && tableData.length > 0;
  }
}
