var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CustomTable',{staticClass:"order-logs-table",attrs:{"options":_vm.tableOptions,"table-data":_vm.logs,"selected-rows":_vm.checkboxController.selectedRows,"bordered-cell":_vm.editableCellHandler.cell},scopedSlots:_vm._u([{key:"headcheckbox",fn:function(){return [_c('BasicCheckbox',{attrs:{"id":"checkbox-head","is-checkbox-checked":_vm.checkboxController.isCheckboxAllChecked(_vm.logs),"callback":function () { return _vm.selectAllRows(_vm.logs); }}})]},proxy:true},{key:"checkbox",fn:function(props){return [_c('BasicCheckbox',{attrs:{"id":("checkbox-" + (props.rowData.id)),"is-checkbox-checked":_vm.checkboxController.isCheckboxChecked(props.rowData.id),"callback":function () { return _vm.selectRow(props.rowData); }}})]}},{key:"createdAt",fn:function(props){return [_vm._v(" "+_vm._s(props.rowData.date)+" ")]}},{key:"employee",fn:function(props){return [_c('EmployeeEditableCell',{attrs:{"is-edit-supported":!_vm.orderDetails.completed,"row-id":props.rowData.id,"text":props.rowData.employee.displayName,"content-id":("name-" + (props.rowData.id) + "-" + (props.rowData.employee.spacelessDisplayName))},on:{"save":function (ref) {
	var employeeId = ref.employeeId;

	return _vm.editEmployee(employeeId, props.rowData);
},"on-active-edit":_vm.updateActiveEditableCell,"on-inactive-edit":_vm.updateActiveEditableCell}})]}},{key:"issueKey",fn:function(props){return [(props.rowData.issueLink)?_c('DLink',{attrs:{"text":props.rowData.issue.key,"link":props.rowData.issueLink}}):_c('span',[_vm._v(_vm._s(props.rowData.issue.key))])]}},{key:"exportPreset",fn:function(props){return [_c('ExportPreset',{attrs:{"is-edit-enabled":!_vm.orderDetails.completed,"selected":props.rowData.exportPreset,"cell-key":"exportPreset","row-ids":[props.rowData.id],"content-id":_vm.exportPresetBoxId(props.rowData.id)},on:{"preset-updated":_vm.onExportPresetUpdate,"on-active-edit":_vm.updateActiveEditableCell,"on-inactive-edit":_vm.updateActiveEditableCell,"specify-reason":function (preset) { return _vm.saveSelectedPreset(preset, props.rowData); }}})]}},{key:"issueSummary",fn:function(props){return [_vm._v(" "+_vm._s(props.rowData.issue.summary)+" ")]}},{key:"comment",fn:function(props){return [_c('EditableTableCell',{attrs:{"is-edit-enabled":!_vm.orderDetails.completed,"cell-key":"comment","row-data":props.rowData,"row-id":props.rowData.id,"content-id":("comment-" + (props.rowData.id))},on:{"save":function (ref) {
	var content = ref.content;

	return _vm.editComment(content, props.rowData);
},"on-active-edit":_vm.updateActiveEditableCell,"on-inactive-edit":_vm.updateActiveEditableCell}})]}},{key:"hours",fn:function(props){return [_c('p',{staticClass:"hours-cell"},[_vm._v(_vm._s(props.rowData.hours))])]}},{key:"ratio",fn:function(props){return [_c('div',{staticClass:"ratio-cell"},[(props.rowData.hasConflict)?_c('ConflictTooltip'):_vm._e(),_c('EditableTableCell',{staticClass:"editable-cell-ratio",attrs:{"is-edit-enabled":!_vm.orderDetails.completed,"cell-key":"ratio","row-data":props.rowData,"content-id":("ratio-" + (props.rowData.id))},on:{"save":function (ref) {
	var content = ref.content;

	return _vm.saveEnteredRatio(content, props.rowData);
},"on-active-edit":_vm.updateActiveEditableCell,"on-inactive-edit":_vm.updateActiveEditableCell}}),_c('ReasonTooltip',{attrs:{"log":props.rowData},on:{"specify-reason":function () { return _vm.specifyReason(props.rowData); }}})],1)]}}])}),(_vm.isSpecifyReasonModalDisplayed)?_c('SpecifyReason',{attrs:{"type-of-change":_vm.typeOfChange,"new-value":_vm.newValueOfChange,"logs":[_vm.activeRow]},on:{"reason-confirmed":_vm.onReasonConfirmed}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }