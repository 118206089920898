<template>
  <div class="type-buttons">
    <div v-for="(type, index) in types"
         :key="type.id"
         :class="{'active': type.isActive}"
         @click="selectType(type, index)"
         class="type">
      <span class="type-text">{{ type.text }}</span>
    </div>
  </div>
</template>

<script>
import {ref} from "@vue/composition-api";

export default {
  name: "TabSelector",
  props: {
    tabs: {
      type: Array,
      required: true
    },
    initialValue: {
      type: Boolean
    }
  },
  emits: ['update'],
  setup(props, {emit}) {
    const types = props.tabs;
    const selectedTypeIndex = ref(0);

    if (props.initialValue) {
      selectedTypeIndex.value = types.findIndex(type => type.isActive);
      emit('update', types[selectedTypeIndex.value]); // Emit the selected value to the parent.
    }

    function selectType(type, typeIndex) {
      types[typeIndex].isActive = true; // Set the clicked action as the active one.
      types[selectedTypeIndex.value].isActive = false; // Set the previous active action as inactive.
      selectedTypeIndex.value = typeIndex; // Save the new type index.

      emit('update', types[typeIndex]); // Emit the selected value to the parent.
    }

    return {
      types,
      selectType
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";

.type-buttons {
  display: flex;
  margin-bottom: rem(40);
  width: 100%;

  .type {
    @include hover-active-pointer();
    align-items: center;
    background-color: var(--gray-main);
    display: flex;
    flex-basis: 50%;
    height: rem(42);
    justify-content: center;

    &:first-child {
      border-radius: rem(5) 0 0 rem(5);

    }

    &:last-child {
      border-radius: 0 rem(5) rem(5) 0;
    }

    .type-text {
      font-size: rem(16);
      font-weight: bold;
      color: var(--gray-light-03);
    }
  }

  .active {
    background-color: var(--black-light);

    .type-text {
      color: white;
    }
  }
}
</style>
