<template>
  <div v-if="!log.isUnchanged" class="reason-tooltip">
    <img :src="commentIcon" class="comment-icon" alt=""/>

    <div class="tooltip-box">
      <!-- The reason has not been specified -->
      <div v-if="log.isChangedWithoutReason" class="reason-not-specified">
        <img src="../../../assets/icons/svg/ic_add.svg" class="add-icon" alt="add"/>
        <p @click="specifyReason()" class="specify-reason">Specify reason</p>
      </div>

      <!-- The reason has been specified -->
      <div v-if="log.excludeReason" class="reason-specified">
        <p class="reason">{{ log.excludeReason }}</p>
        <p @click="specifyReason()" class="specify-reason edit-reason">Edit reason</p>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, ref} from "@vue/composition-api";

export default {
  name: "ReasonTooltip",
  props: {
    log: {
      type: Object,
      required: true
    }
  },
  setup(props, {emit}) {
    const commentIcon = computed(() => props.log.isChangedWithoutReason ? require('../../../assets/icons/svg/ic_comment.svg') : require('../../../assets/icons/svg/ic_comment_red.svg'));
    const specifyReason = () => emit('specify-reason');

    return {
      commentIcon,
      specifyReason,
    }
  }
}
</script>


<style scoped lang="scss">
@import "../../../assets/css/base.mixins";

.reason-tooltip {
  @include position-elevation(absolute, $top: 0, $right: rem(-40), $elevation: 50);
  color: white;
  display: inline-block;
  font-family: "Open Sans";

  .comment-icon {
    @include hover-active-pointer;
    height: rem(14);
    width: rem(14);
  }

  @include hover-active-pointer {
    .tooltip-box {
      display: inline-block;
    }
  }
}

.tooltip-box {
  @include position-elevation(absolute, $top: calc(100% + 6px), $right: -13px, $elevation: 50);
  background-color: var(--black-light);
  border-radius: rem(8);
  box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0);
  display: none;
  padding: rem(15) rem(0);
  width: rem(199);
  @include on-hover {
    cursor: default;
  }

  .manager-link {
    color: #E65540;
    font-weight: bold;
    text-decoration: underline;
  }

  &::before {
    @include position(absolute, $top: rem(-10), $right: 0);
    border-color: transparent transparent var(--black-light) transparent;
    border-style: solid;
    border-width: 0 20px 20px 20px;
    border-radius: rem(8);
    content: "";
    height: 0;
    width: 0;
    z-index: 12;
  }
}

// Content
.reason-not-specified {
  align-items: center;
  display: flex;
  justify-content: center;

  .add-icon {
    height: rem(11);
    margin-right: rem(10);
    width: rem(11);
  }
}

.specify-reason {
  @include hover-active-pointer;
  color: white;
  font-size: rem(14);
  font-weight: bold;
  line-height: rem(19);
  text-decoration: underline;
}

.reason-specified {
  text-align: center;

  .reason {
    font-size: rem(14);
    line-height: rem(19);
  }

  .edit-reason {
    color: #E65540;
    margin-top: rem(10);
  }
}
</style>
