<template>
  <div class="conflict-tooltip">
    <img src="../../assets/icons/svg/ic_report.svg" class="report-icon" alt=""/>

    <!-- Tooltip -->
    <div class="tooltip-box">
      <p class="tooltip-text">This log is conflicted.<br/>Resolve the conflict in the<br/>
        <router-link :to="{name: ROUTE_NAMES_CONFLICT_MANAGER.CONFLICT_MANAGER}" class="manager-link">Conflict Manager</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import {ROUTE_NAMES_CONFLICT_MANAGER} from "@/router/modules/conflictManager";

export default {
  name: "ConflictTooltip",
  setup() {
    return {
      ROUTE_NAMES_CONFLICT_MANAGER
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";

.conflict-tooltip {
  display: inline-block;
  position: relative;
  text-align: center;

  .report-icon {
    height: rem(16);
    width: rem(16);
  }

  @include hover-active-pointer {
    .tooltip-box {
      display: inline-block;
    }
  }
}

.tooltip-box {
  @include position-elevation(absolute, $top: calc(100% + 6px), $left: 50%, $elevation: 50);
  background-color: var(--black-light);
  border-radius: rem(8);
  box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0);
  display: none;
  padding: rem(10) rem(16);
  transform: translateX(-50%);
  width: rem(199);

  .tooltip-text {
    font-family: "Open Sans";
    color: white;
    font-size: rem(14);
    text-align: center;

    .manager-link {
      color: #E65540;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  &::before {
    @include position(absolute, $top: rem(-10), $left: 50%);
    //border-color: transparent transparent var(--black-light) transparent;
    border-color: transparent transparent var(--black-light) transparent;
    border-style: solid;
    border-width: 0 20px 20px 20px;
    border-radius: rem(8);
    content: "";
    height: 0;
    transform: translateX(-50%);
    width: 0;
    z-index: 12;
  }
}
</style>
