export default function initializeOrderLogsTable() {
  const tableOptions = {
    headings: {
      'checkbox': '',
      'createdAt': 'Date',
      'employee': 'Employee',
      'issueKey': 'Issue',
      'issueSummary': 'Summary',
      'comment': 'Comment',
      'exportPreset': 'Export preset',
      'hours': 'Hours',
      'ratio': 'Ratio'
    },
    sortable: {
      'checkbox': false,
      'createdAt': false,
      'employee': false,
      'issueKey': false,
      'issueSummary': false,
      'comment': false,
      'exportPreset': false,
      'hours': false,
      'ratio': false,
    },
    editable: {
      'checkbox': false,
      'createdAt': false,
      'employee': true,
      'issueKey': false,
      'issueSummary': false,
      'comment': true,
      'exportPreset': true,
      'hours': false,
      'ratio': true,
    },
  };

  return {tableOptions};
}